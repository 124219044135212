import $backend from './backend'

export const createItem = async (listId: String, name: String) => {
  return $backend.sendResource(`/todo/${listId}/items`, { name })
}

export const setItemAsDone = async (itemId: String) => {
  const url = `/items/${itemId}/done`
  return $backend.updateResource(url, {})
}

export const updateItem = async (itemId: String, name: String) => {
  return $backend.updateResource(`/items/${itemId}`, { name })
}
