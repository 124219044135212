import axios from 'axios'

let $axios = axios.create({
  baseURL: '/api/public/',
  timeout: 5000,
  headers: { 'Content-Type': 'application/json' }
})

// Request Interceptor
$axios.interceptors.request.use(function (config) {
  config.headers['Authorization'] = 'Fake Token'
  return config
})

// Response Interceptor to handle and log errors
$axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  // Handle Error
  // console.log(error)
  return Promise.reject(error)
})

interface ReqBody {
    [key: string]: any
}

export default {
  async fetchResource (url: string) {
    return $axios.get(url)
      .then(response => response.data)
  },

  async sendResource (url: string, data: ReqBody) {
    return $axios.post(url, data)
      .then(response => response.data)
  },

  async updateResource (url: string, data: ReqBody) {
    return $axios.put(url, data)
      .then(response => response.data)
  }
}
