import $backend from './backend'

export const getAllLists = async () => {
  return $backend.fetchResource('/todo')
}

export const getList = async (listId: String) => {
  return $backend.fetchResource(`/todo/${listId}`)
}

export const createList = async (name: String, isPublic: boolean) => {
  return $backend.sendResource('/todo', { name, public: isPublic })
}

export const setListAsDone = async (listId: String) => {
  return $backend.updateResource(`/todo/${listId}/done`, {})
}
